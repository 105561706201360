import request from '@/utils/request'

// api地址
const api = {
  all: '/sys/userReal',
  page: '/sys/userReal/page',
  agree: '/jyyw/hero/realPass',
  turnDown: '/jyyw/hero/realRefuse',
  relieve: '/jyyw/hero/remove'
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    console.log(api.all)
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, { params: params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 同意
 * @param id id
 * @returns {Promise<>}
 */
export function agree(parems) {
  return new Promise((resolve, reject) => {
    request.post(api.agree, parems).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 驳回
 * @param id id
 * @returns {Promise<>}
 */
export function turnDown(parems) {
  return new Promise((resolve, reject) => {
    request.post(api.turnDown, parems).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 移除
 * @param id id
 * @returns {Promise<>}
 */
export function relieve(parems) {
  return new Promise((resolve, reject) => {
    request.post(api.relieve, parems).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
