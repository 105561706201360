import request from '@/utils/request'

// api地址
const api = {
  page: '/frm/client',
  update: '/frm/client/update',
  realPass: '/frm/client/realPass',
  realRefuse: '/frm/client/realRefuse'
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
// 通过实名认证
export function realPass(data) {
  return new Promise((resolve, reject) => {
    request.post(api.realPass, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
// 拒绝实名认证
export function realRefuse(data) {
  return new Promise((resolve, reject) => {
    request.post(api.realRefuse, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 更新
 * @param data 参数
 * @returns
 */
export function update(data) {
  return new Promise((resolve, reject) => {
    request.post(api.update, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
